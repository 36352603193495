import * as React from "react"
import { Tabs, Tab } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComponentInnovacion from "../hooks/innovacion";
import ComponentCompetencias from "../hooks/competencias";
import ComponentRecursos from "../hooks/recursos";
import ComponentLaboratorio from "../hooks/laboratorio";

export default function OpenResearchLab() {
    return (
        <Layout pageInfo={{ pageName: "OpenResearchTec" }}>
            <SEO title="OpenResearchTec" />
            <br />
            <br />
            <br></br>
            <Tabs defaultActiveKey="laboratorio" id="uncontrolled-tab-example" className="mb-4 nav-fill integrantes-tab">

                <Tab eventKey="laboratorio" style={{textAlign: `justify`}} className="pt-3" title="Laboratorio virtual">
                    <ComponentLaboratorio></ComponentLaboratorio>
                </Tab>
                <Tab eventKey="innovacion" style={{textAlign: `justify`}} className="pt-3" title="Innovación educativa">
                    <ComponentInnovacion></ComponentInnovacion>
                </Tab>
                <Tab eventKey="competencias" style={{textAlign: `justify`}} className="pt-3" title="Competencias">
                    <ComponentCompetencias></ComponentCompetencias>
                </Tab>
                <Tab eventKey="recursos" style={{textAlign: `justify`}} className="pt-3" title="Recursos educativos">
                    <ComponentRecursos></ComponentRecursos>
                </Tab>
            </Tabs>
        </Layout>
    )
}