import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentCompetencias = () => {
    const data = useStaticQuery(graphql`
    {
      nodePage(id: {eq: "73259e36-4b3d-5d99-b44e-50f806465049"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }}></div>
        </div>
    )
}

export default ComponentCompetencias

