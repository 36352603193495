import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentInnovacion = () => {
    const data = useStaticQuery(graphql`
    {
      nodePage(id: {eq: "e39f22b0-1e63-5f33-8460-5d6e50503826"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }}></div>
        </div>
    )
}

export default ComponentInnovacion

