import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentLaboratorio = () => {
    const data = useStaticQuery(graphql`
    {
      nodePage(id: {eq: "eb94744c-b1d0-5e0a-9186-37ca29b6c472"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }}></div>
        </div>
    )
}

export default ComponentLaboratorio

